<template>
    <!-- tmc -->
    <div class="row">
        <div class="col-xl-6 ">
            <div class="form-group">
                <label>Pilihan Metode Konsultasi Dokter :</label>
                <div class="form-check">
                    
                    <label class="form-check-label pointer">
                        <input
                        class="form-check-input pointer"
                        type="radio"
                        :value="false"
                        v-model="telmed"
                        name="telekonsul"
                    />
                        Datang Langsung (default)
                    </label>
                </div>
                <div class="form-check">
                    <label class="form-check-label pointer">
                        <input
                        class="form-check-input pointer"
                        type="radio"
                        :value="true"
                        v-model="telmed"
                        name="telekonsul"
                    />Telekonsultasi
                    </label>
                </div>
            </div>
        </div>
    </div>
    <!-- tmc end -->
</template>

<script>
export default {
    data(){
        return {
            // telmed: false
        }
    },
    // emits: ['selectedTeleconsult'],
    props:{
        selectTeleconsult: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        telmed: {
            get () {
                return this.selectTeleconsult
            },
            set (val) {
                this.$emit('selectedTeleconsult', val) 
            }
        }
    },
    created() {
        // this.$emit('teleconsult', false)
        // if(this.checkTeleconsult && this.checkTeleconsult.reservasi) {
        //     if(this.checkTeleconsult.reservasi.teleconsultPayment) {
        //         this.telmed = true
        //     } else {
        //         this.telmed = false
        //     }
        // } else {
        //     this.telmed = false
        // }
        // setTimeout(()=> {
        //     this.$emit('teleconsult', this.telmed)
        // }, 500)
         
    },
    watch:{
        // "selectedDokter.length"(newVal, oldVal){
        //     if(!newVal){
        //         this.telmed = false
        //         this.change()
        //     }
        // }
    },
    methods: {
        // change(evt){
        //     console.log('masuk ', evt)
        //     this.$emit('selectedTeleconsult', evt.target.value)
        // }
    }
}
</script>

<style>

</style>