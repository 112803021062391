<template>
    <div>
        <div class="row">
            <div class="col-12 text-right">
                <button
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon"
                    @click="showHelp"
                >
                    <i class="fas fa-question"></i>
                </button>
            </div>
        </div>
        <div class="kt-heading kt-heading--md">Reservasi</div>
        <div class="row">
            <div class="col-xl-6">
                <div class="form-group">
                    <label>Pilih Tanggal Kunjungan:</label>
                    <vue-date-picker
                        v-model="reservasi.tanggalKunjungan"
                        :scope="'form-2'"
                        :validation="'required'"
                        :validationName="'Tanggal Kunjungan'"
                        :validationMin="true"
                        :name="'tanggalKunjungan'"
                        :change="change"
                        :clearable="false"
                        :editable="false"
                    ></vue-date-picker>
                    <small
                        v-if="yesterdayDate"
                        id="passwordHelpBlock"
                        class="form-text text-danger"
                    >
                        Tanggal minimal hari ini</small
                    >
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6">
                <div class="form-group">
                    <label>Pilih Dokter:</label>
                    <template v-if="isDefaultDoctor">
                        <select-doktor
                            v-model="selectedDokter"
                            :internal-search="false"
                            :grouping="true"
                            :multiple="true"
                            :group-select="true"
                            :scope="'form-2'"
                            :validation="'required'"
                            :validationName="'Dokter'"
                            :name="'Dokter'"
                            :options="optionDoctors"
                            :search-change="searchChangeGroup"
                            :loading="loadingdokter"
                            :remove="remove"
                            :select="select"
                        ></select-doktor>
                    </template>
                    <template v-else>
                        <select-doktor
                            v-model="selectedDokter"
                            :options="optionNotPoli"
                            :loading="loadingdokter"
                            :is-show-tujuan="true"
                            :remove="remove"
                            :multiple="true"
                            :select="select"
                        ></select-doktor>
                    </template>
                    <label class="mt-2">
                        <input type="checkbox" v-model="isDefaultDoctor" /> With
                        Spesialis
                    </label>
                </div>
            </div>
        </div>
        <template v-if="!loadingdokter">
            <metode-konsultasi
                :selectTeleconsult="isTeleconsults"
                @selectedTeleconsult="selectTelekonsul"
            />
        </template>
        <div class="row mb-4">
            <div class="col-xl-12">
                <div
                    class="form-group mb-2 row"
                    v-if="jadwalStatics.doctorName"
                >
                    <div class="col-md-12">
                        <label>Jadwal Terpilih</label>
                    </div>
                    <div class="col-md-8">
                        <p class="mb-1">{{ jadwalStatics.doctorName }}</p>
                        <p>
                            Jam :
                            <label
                                >{{ jadwalStatics.jamMulai }} -
                                {{ jadwalStatics.jamSelesai }}</label
                            >
                        </p>
                    </div>
                    <div class="col-md-4" v-if="isTeleconsults">
                        <div v-if="editPricing">
                            <div class="input-group">
                                <label class="mt-2 mr-1"> Rp </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="price"
                                    placeholder="200.000"
                                />
                                <div
                                    class="input-group-append"
                                    v-if="isCheckOverride"
                                >
                                    <button
                                        class="btn btn-default btn-bold"
                                        type="button"
                                        @click="updatePrice()"
                                    >
                                        <i class="fa fa-save"></i>
                                    </button>
                                </div>
                            </div>
                            <small for="" class="text-danger"
                                >*) Simpan dahulu, Jika tidak harga akan kembali
                                ke normal</small
                            >
                        </div>
                        <div class="mt-2" v-else>
                            <span>Pricing : {{ formatedPrice }} </span>
                            <template v-if="isCheckOverride">
                                <button
                                    type="button"
                                    class="btn btn-outline-hover-primary btn-sm btn-icon btn-circle"
                                    @click="editPricing = true"
                                >
                                    <i class="fa fa-edit kt-font-primary"></i>
                                </button>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="ml-5" v-if="tipe != undefined && tipe == 'DEFECT'">
                    <template>
                        <span class="text-danger">
                            Jadwal tidak sesuai slot, silahkan perbaiki dengan
                            memilih slot yang baru disini.
                        </span>
                    </template>
                </div>
                <label class="col-md-2" v-if="jadwalStatics.doctorName"
                    >Pilih Jadwal </label
                >

                <select-jadwal
                    v-if="queueUnitSlot"
                    v-show="!loadingSelectJadwal"
                    :options="selectedDokter"
                    :date="reservasi.tanggalKunjungan"
                    :name="'selectJadwal'"
                    :validation="'required'"
                    :scope="'form-2'"
                    :validation-name="'Jadwal'"
                    :isTelekonsul="teleconsult"
                    :queueUnitSlot="queueUnitSlot"
                    v-model="reservasiClone"
                ></select-jadwal>
                <div
                    v-if="loadingSelectJadwal"
                    class="m-auto p-5 d-flex flex-row h-100"
                >
                    <div
                        class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"
                    ></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
            </div>
        </div>
        <!-- tmc lagi -->
        <div v-if="isTeleconsults && isSelectedSlot">
            <div class="form-group col-8">
                <label>Pilih opsi kirim link pembayaran :</label>
                <div class="row">
                    <div class="col-1">
                        <input
                            type="checkbox"
                            id="Mobile"
                            :value="disableOptions[0].name"
                            :checked="disableOptions[0].checked"
                            :disabled="disableOptions[0].disabled"
                            @input="
                                disableOptions[0].checked =
                                    $event.target.checked
                            "
                        />
                    </div>
                    <div class="col-4">
                        <label
                            :for="disableOptions[0].name"
                            :aria-disabled="disableOptions[0].disabled"
                            >{{ disableOptions[0].name }}</label
                        >
                    </div>
                    <div class="col-4">
                        <label
                            :for="disableOptions[0].detail"
                            :aria-disabled="disableOptions[0].disabled"
                            >{{ disableOptions[0].detail }}</label
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col-1">
                        <input
                            type="checkbox"
                            id="SMS"
                            :value="disableOptions[1].name"
                            :checked="disableOptions[1].checked"
                            :disabled="disableOptions[1].disabled"
                            @input="
                                disableOptions[1].checked =
                                    $event.target.checked
                            "
                        />
                    </div>
                    <div class="col-4">
                        <label
                            :for="disableOptions[1].name"
                            :aria-disabled="disableOptions[1].disabled"
                            >{{ disableOptions[1].name }}</label
                        >
                    </div>
                    <div class="col-7">
                        <phone-number-input
                            :data="disableOptions[1].detail"
                            :is-validate="true"
                            :changedValue.sync="disableOptions[1].detail"
                            :phone-patient="false"
                            :scope="'form-2'"
                        >
                        </phone-number-input>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-1">
                        <input
                            type="checkbox"
                            id="Email"
                            :value="disableOptions[2].name"
                            :checked="disableOptions[2].checked"
                            :disabled="disableOptions[2].disabled"
                            @input="
                                disableOptions[2].checked =
                                    $event.target.checked
                            "
                        />
                    </div>
                    <div class="col-4">
                        <label
                            :for="disableOptions[2].name"
                            :aria-disabled="disableOptions[2].disabled"
                            >{{ disableOptions[2].name }}</label
                        >
                    </div>
                    <div class="col-7">
                        <vue-input-email
                            v-model="disableOptions[2].detail"
                            :validationName="'Email'"
                            :scope="'form-2'"
                            :placeholder="'Email'"
                            :name="'email'"
                            @change="
                                disableOptions[2].detail
                                    ? (disableOptions[2].checked = true)
                                    : (disableOptions[2].checked = false)
                            "
                        >
                        </vue-input-email>
                    </div>
                </div>
            </div>
        </div>
        <!-- tmc end -->
        <div class="row wizard-seperator-reverse">
            <div class="col-6 text-left">
                <button
                    type="button"
                    class="btn btn-outline-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                    @click="go(1)"
                >
                    {{ $t("button.previous") }}
                </button>
            </div>
            <div class="col-6 text-right">
                <button
                    type="button"
                    id="nextButton"
                    :disabled="(
                        !isJadwal ||
                        (!linkPaymentOptions[1].checked &&
                            !linkPaymentOptions[2].checked)) "
                    class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                    @click="submit()"
                >
                    {{ $t("button.nextStep") }}
                </button>
                <small class="d-block text-danger mt-1" v-if="!isJadwal">* Silahkan Pilih Jadwal terlebih dahulu</small>
                <!-- <small class="d-block text-danger mt-1" v-if="!isAllowed"
                    >* Silahkan pilih sesuai metode konsultasi dokter</small
                > -->
                <small
                    class="d-block text-danger mt-1"
                    v-if="
                        !linkPaymentOptions[1].checked &&
                        !linkPaymentOptions[2].checked
                    "
                    >* Opsi kirim link pembayaran belum dipilih, mohon pilih
                    minimal 1 opsi (SMS/Email)</small
                >
            </div>
        </div>
        <ModalHelp v-if="showModalHelp" @closeModal="closeModal"></ModalHelp>
    </div>
</template>

<script>
import { RepositoryFactory } from "./../../../../repositories/RepositoryFactory";
const DoctorRepository = RepositoryFactory.get("doctor");
const QueueUnitRepository = RepositoryFactory.get("queueUnit");
import VueMultiSelect from "./../../../_select/VueMultiSelect.vue";
import VueDatePicker from "./../../../_general/VueDatePicker.vue";
import SelectJadwal from "./../../../_select/SelectJadwal.vue";
import SelectDoktor from "./../../../_select/Doktor.vue";
// import { mapState, mapMutations, mapActions } from "vuex";
// import SlotJadwalType from "./../../../../constants/slot-jadwal-type-enum";
import ModalHelp from "./ModalHelp.vue";
import VueInputEmail from "./../../../_general/VueInputEmail";
import PhoneNumberInput from "./../../../_general/PhoneNumber";
import CheckPermission from "./../../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import MetodeKonsultasi from "./../_components/MetodeKonsultasi";

export default {
    provide() {
        return {
            $validator: this.$validator,
        };
    },
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: [Object],
        tipe: {
            type: String,
        },
    },
    computed: {
        isJadwal () {
            if(this.isSelectedSlot) {
                return true
            }
            return false
        },
        // isJadwal () {
        //     return this.reservasi && this.reservasi.dokter && this.reservasi.dokter.id
        // },
        isTeleconsults: {
            get() {
                return this.reservasi.teleconsult;
            },
            set(val) {
                (this.reservasi.teleconsult = val),
                (this.teleconsult = val)
            },
        },
        reservasiClone: {
            get() {
                this.isTeleconsult = this.checkTeleconsult(this.reservasi);
                return {
                    id: this.reservasi.dokter ? this.reservasi.dokter.id : '',
                    jamMulai: this.reservasi.jamMulai,
                    jamSelesai: this.reservasi.jamSelesai,
                    select: this.reservasi.select
                };
            },
            set(newValue) {
                    this.jadwalStatics = this.mappingJadwalStatic(newValue),
                    this.price = this.selectedPrice(newValue),
                    (this.reservasi.jamMulai = newValue.jamMulai),
                    (this.reservasi.dokter = newValue.jadwal.dokter),
                    (this.reservasi.jamSelesai = newValue.jamSelesai),
                    (this.isSelectedSlot = true),
                    (this.reservasi.select = newValue.select);
            },
        },
        reservasi() {
            const pendingChanges = {};
            return new Proxy(this.value, {
                get: (target, key) => target[key],
                set: (target, key, newValue) => {
                    pendingChanges[key] = newValue;
                    this.$emit("change", { ...target, ...pendingChanges });
                    return true;
                },
            });
        },
        selectedDokter: {
            get() {
                if (this.reservasi.dokter) {
                    const found = !!this.listDokterTerpilih.find(
                        (x) => x.id == this.reservasi.dokter.id
                    );
                    if (!found && this.isDefaultDoctor) {
                        this.listDataDokterGroup.forEach((x) => {
                            const find = x.doctors.find(
                                (y) => y.id == this.reservasi.dokter.id
                            );
                            if (find) this.listDokterTerpilih.push(find);
                        });
                    }
                }
                // if (this.listDokterTerpilih.length > 0) {
                //     this.listDokterTerpilih.forEach((item) => {
                //         if (item.tarif) this.checkDataDokter(item);
                //     });
                // }
                return this.listDokterTerpilih;
            },
            set(newValue) {
                if(newValue.length == 0) delete this.reservasi.dokter
                this.jadwalStatics = this.setInfoStaticDokter(this.jadwalStatics, newValue)
                this.listDokterTerpilih = newValue;
            },
        },
        parameter() {
            return {
                "tanggalMulai.equals": moment(
                    this.reservasi.tanggalKunjungan
                ).format("YYYY-MM-DD"),
                "tanggalSelesai.equals": moment(
                    this.reservasi.tanggalKunjungan
                ).format("YYYY-MM-DD"),
            };
        },
        disableOptions() {
            if (this.reservasi && this.reservasi.patient.createdBy == "admin") {
                this.linkPaymentOptions[0].disabled = false;
                this.linkPaymentOptions[0].checked = false;
                if (this.reservasi.patient.mobilePhone1) {
                    this.linkPaymentOptions[1].detail =
                        this.reservasi.patient.mobilePhone1;
                } else {
                    this.linkPaymentOptions[1].checked = false;
                }
                if (this.reservasi.patient.email) {
                    this.linkPaymentOptions[2].detail =
                        this.reservasi.patient.email;
                } else {
                    this.linkPaymentOptions[2].checked = false;
                }
                return this.linkPaymentOptions;
            } else {
                if (this.reservasi.patient.mobilePhone1) {
                    this.linkPaymentOptions[1].detail =
                        this.reservasi.patient.mobilePhone1;
                } else {
                    this.linkPaymentOptions[1].checked = false;
                    this.linkPaymentOptions[1].detail = null;
                }
                if (this.reservasi.patient.email) {
                    this.linkPaymentOptions[2].detail =
                        this.reservasi.patient.email;
                } else {
                    this.linkPaymentOptions[2].checked = false;
                    this.linkPaymentOptions[2].detail = null;
                }
                return this.linkPaymentOptions;
            }
        },
        formatedPrice() {
            let price = parseFloat(this.price);
            return price.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
            });
        },
    },
    components: {
        VueMultiSelect,
        VueDatePicker,
        SelectJadwal,
        SelectDoktor,
        ModalHelp,
        VueInputEmail,
        PhoneNumberInput,
        MetodeKonsultasi,
    },
    data() {
        return {
            isCheckOverride: false,
            dokterOverideTarif: [],
            isTeleconsult: false,
            isDefaultDoctor: false,
            poli: null,
            listDokterTerpilih: [],
            listDataDokterGroup: [],
            optionDoctors: [],
            optionNotPoli: [],
            cloneOptions: [],
            loadingdokter: false,
            listReservasi: [],
            listInternalEvent: [],
            listDefect: [],
            loadingSelectJadwal: false,
            showModalHelp: false,
            yesterdayDate: false,
            isSelectedSlot: false,
            jadwalStatics: {
                id: 0,
                doctorName: "",
                jamMulai: "",
                jamSelesai: "",
                price: 0,
            },
            teleconsult: false,
            linkPaymentOptions: [
                {
                    id: 1,
                    name: "Mobile",
                    checked: true,
                    disabled: false,
                    detail: null,
                    update: false,
                },
                {
                    id: 2,
                    name: "SMS",
                    checked: true,
                    disabled: false,
                    detail: null,
                    update: false,
                },
                {
                    id: 3,
                    name: "Email",
                    checked: true,
                    disabled: false,
                    detail: null,
                    update: false,
                },
            ],
            editPricing: false,
            price: 0,
            firstPrice: 0,
            doctorLogin: checkPermission.isCanAccess(
                "DokterResource.GET.Dokter.Current"
            ),
            // haveTeleconsult: false,
            abortController: null,
            queueUnitSlot: null
        };
    },
    watch: {
        isDefaultDoctor() {
            this.selectedDokter = [];
        },
        reservasi: function (val) {
            let today = moment().format("YYYY-MM-DD");
            let tgl = moment(val.tanggalKunjungan).format("YYYY-MM-DD");
            if (tgl < today) {
                this.yesterdayDate = true;
                // document.getElementById("nextButton").disabled = true;
            } else {
                this.yesterdayDate = false;
                // document.getElementById("nextButton").disabled = false;
            }
        },
    },
    methods: {
        selectedInitDokter(listDokter, selectedId) {
            if(!selectedId) return []
            const selectedDokter = listDokter.filter(_item => _item.id == selectedId)
            return selectedDokter
        },
        setInfoStaticDokter(staticJadwal, selectDokter) {
            const isHaveSelect = selectDokter.some(_item => _item.id == staticJadwal.id)
            if(isHaveSelect) return staticJadwal
            delete this.reservasi.dokter
            this.isSelectedSlot = false
            return {}
        },
        mappingJadwalStatic (data ) {
            let price = this.price ?? 0
            if (this.jadwalStatics.id && data.id != this.jadwalStatics.id) {
                price = data.jadwal.dokter && data.jadwal.dokter.tarif && data.jadwal.dokter.tarif.jumlah
            }
            return {
                price,
                id: data.id,
                doctorName: data.jadwal.dokter.nama,
                jamMulai: data.jamMulai,
                jamSelesai: data.jamSelesai,
            }
        },
        selectedPrice (data) {
            if(data.id == this.jadwalStatics.id) return this.jadwalStatics.price
            const price = data.jadwal.dokter && data.jadwal.dokter.tarif && data.jadwal.dokter.tarif.jumlah
            return price 
        },
        selectTelekonsul(val) {
            this.isTeleconsults = val;
        },
        checkDataDokter(item) {
            const found = !!this.dokterOverideTarif.find(
                (x) => x.id == item.id
            );
            if (!found) {
                const data = {
                    id: item.id,
                    jumlah: item.tarif.jumlah,
                };
                this.dokterOverideTarif.push(data);
            }
        },
        checkTeleconsult(data) {
            if (data.jadwal && data.jadwal.dokter && data.jadwal.dokter.tarif) {
                return true;
            }
            return false;
        },
        searchChangeGroup(query) {
            const data = this.listDataDokterGroup.filter((x) => {
                if (
                    x.doctors.find(
                        (y) =>
                            y.nama.toLowerCase().indexOf(query.toLowerCase()) >
                            -1
                    )
                ) {
                    return x;
                } else if (
                    x.nama.toLowerCase().indexOf(query.toLowerCase()) > -1
                ) {
                    return x;
                }
            });
            this.optionDoctors = data;
        },
        submit() {
            this.$validator.validateAll("form-2").then((result) => {
                if (result) {
                    // if(this.value.jadwal !== null){
                    // this.reservasi.teleconsult = this.teleconsult
                    if (this.reservasi.teleconsult) {
                        this.reservasi.smsNotif = this.linkPaymentOptions[1]
                            .checked
                            ? this.linkPaymentOptions[1].detail
                            : null;
                        this.reservasi.emailNotif = this.linkPaymentOptions[2]
                            .checked
                            ? this.linkPaymentOptions[2].detail
                            : null;
                        if (this.price != this.firstPrice) {
                            this.reservasi.isOverride = true;
                        } else {
                            this.reservasi.isOverride = false;
                        }
                    }
                    this.go(3);
                }
            });
        },
        go(step) {
            this.$emit("go", step);
        },
        async getDataDokter() {
            // let abortFunc
            if (this.abortController) {
                this.abortController.abort();
                this.abortController = null;
            }
            //  = new AbortController();
            try {
                this.loadingdokter = true;
                var data = [];
                let response;
                if (this.doctorLogin) {
                    response = await new Promise((resolve, reject) => {
                        DoctorRepository.getCurrentDoctors(
                            {
                                "tanggalMulai.equals": moment(
                                    this.reservasi.tanggalKunjungan
                                ).format("YYYY-MM-DD"),
                                "tanggalSelesai.equals": moment(
                                    this.reservasi.tanggalKunjungan
                                ).format("YYYY-MM-DD"),
                            },
                            resolve,
                            reject
                        );
                    });
                } else {
                    response = await new Promise((resolve, reject) => {
                        this.abortController =
                            DoctorRepository.getDokterModalReservasi(
                                {
                                    "tanggalMulai.equals": moment(
                                        this.reservasi.tanggalKunjungan
                                    ).format("YYYY-MM-DD"),
                                    "tanggalSelesai.equals": moment(
                                        this.reservasi.tanggalKunjungan
                                    ).format("YYYY-MM-DD"),
                                    // sort: "nama"
                                    sort: "asc",
                                    page_size: 20,
                                },
                                resolve,
                                reject
                            );
                    });
                }

                response.data.forEach((x) => {
                    let dataDokter = x;
                    dataDokter.tujuanRujukan = this.getDefaultSpesialisasi(
                        dataDokter.dokterSpesialisasis
                    );
                    const idDokter = dataDokter.tujuanRujukan
                        ? dataDokter.tujuanRujukan.id
                        : "";
                    if (idDokter) {
                        var index = data.findIndex((y) => y.id == idDokter);
                        if (index < 0) {
                            const poly = { ...dataDokter.tujuanRujukan };
                            poly.doctors = [];
                            data.push(poly);
                            index = data.length - 1;
                        }
                        data[index].doctors.push(dataDokter);
                    }
                });
                this.listDataDokterGroup = [...data];
                this.optionDoctors = [...data];
                this.optionNotPoli = response.data;
                if(this.reservasi && this.reservasi.dokter && this.reservasi.dokter.id) {
                    const idDokter = this.reservasi.dokter.id
                    this.listDokterTerpilih = this.selectedInitDokter(response.data, idDokter)
                }
                this.loadingdokter = false;
                this.getListReservasi(this.selectedDokter.map((x) => x.id));
            } catch (err) {
                toastr.error("Failed fetch list dokter");
            }
        },
        getDefaultSpesialisasi(dokterSpesialisasi) {
            let defaultSpesialisasi = null;
            if (Array.isArray(dokterSpesialisasi)) {
                dokterSpesialisasi.forEach((item) => {
                    // console.log(item,'itemmss');
                    if (item.isDefault || item.default) {
                        if (item.spesialisasi) {
                            defaultSpesialisasi = this.spesialisasiMapper(
                                item.spesialisasi
                            );
                        }
                    }
                });
                return defaultSpesialisasi;
            }
        },
        spesialisasiMapper(spesialisasi) {
            if (spesialisasi) {
                return {
                    id: spesialisasi.ID ? spesialisasi.ID : spesialisasi.id,
                    nama: spesialisasi.namaIndo,
                };
            }
        },
        async getListReservasi(ids) {
            if (ids.length > 0) {
                try {
                    this.loadingSelectJadwal = true;
                    this.listReservasi = [];
                    this.listDefect = [];
                    var momentDay = moment(this.reservasi.tanggalKunjungan);
                    var paramQueue = {
                        // reservasiStatuses: "NEW, CONFIRM, ARRIVED",
                        // tanggalKunjunganMulai: momentDay.format("YYYY-MM-DD"),
                        // tanggalKunjunganSelesai: momentDay.format("YYYY-MM-DD"),
                        dokterId: ids.join(","),
                        // dayOfWeeks: momentDay.locale('en').format('dddd').toUpperCase(),
                        // tanggalJadwalMulai: momentDay.format("YYYY-MM-DD"),
                        tanggal: momentDay.format("YYYY-MM-DD"),
                    };
                    const paramQueue1 = new URLSearchParams();
                    paramQueue1.append(
                        "tanggal",
                        momentDay.format("YYYY-MM-DD")
                    );
                    ids.forEach((_item) => {
                        paramQueue1.append("dokterId", _item);
                    });
                    let responseQueue;
                    if (this.doctorLogin) {
                        responseQueue = await new Promise((resolve, reject) => {
                            QueueUnitRepository.getDoctorQueueSlot(
                                paramQueue,
                                resolve,
                                reject
                            );
                        });
                    } else {
                        responseQueue = await new Promise((resolve, reject) => {
                            QueueUnitRepository.getQueueUnitSlotDokter(
                                paramQueue1
                            )
                                .then((_resp) => _resp.data)
                                .then((_data) => {
                                    resolve(_data);
                                });
                        });
                    }
                    const map = helpers.splitingReservation(
                        responseQueue,
                        momentDay.format("YYYY-MM-DD")
                    );
                    if(this.reservasi.isNewReserved) {
                        const isHaveReservation = helpers.selectHaveReservation(this.reservasi.jamMulai, this.reservasi.jamSelesai, responseQueue)
                        if(isHaveReservation) {
                            delete this.reservasi.jamMulai
                            delete this.reservasi.jamSelesai
                            this.jadwalStatics.jamMulai = ''
                            this.jadwalStatics.jamSelesai = ''
                        }
                    }
                    this.queueUnitSlot = responseQueue;
                    this.listReservasi = map.reservasiAppointment;
                    this.listDefect = map.reservasiDefect;
                    this.listInternalEvent = [
                        ...map.internalEvent,
                        ...map.listTelat,
                    ];
                    this.loadingSelectJadwal = false;
                } catch (err) {
                    console.log(err, "error fetchd ata");
                    toastr.error("Failed Fetch Data");
                }
            } else {
                this.listReservasi = [];
                this.listDefect = [];
            }
        },
        select(data) {
            setTimeout(() => {
                this.getListReservasi(this.selectedDokter.map((x) => x.id));
            }, 10);
        },
        remove(data) {
            if (
                this.reservasi.jadwal != null &&
                data.id == this.reservasi.jadwal.dokter.id
            ) {
                this.reservasi.jadwal = null;
            }
        },
        change(val) {
            this.listDokterTerpilih = [];
            this.reservasi.jadwal = null;
            this.jadwalStatics = {}
            this.isSelectedSlot = false
            setTimeout(() => {
                this.getDataDokter();
            }, 50);
        },
        showHelp() {
            this.showModalHelp = true;
        },
        closeModal() {
            this.showModalHelp = false;
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
        updatePrice() {
            this.jadwalStatics.price = this.price;
            this.editPricing = false;
        },
        // changeTelmed(val) {
        //     this.teleconsult = val;
        // },
    },
    mounted() {
        this.isCheckOverride =
            this.checkPermission(
                "ReservasiResource.PUT.Reservasi.BackOffice.OverrideTarif"
            ) &&
            this.checkPermission(
                "ReservasiResource.POST.Reservasi.BackOffice.OverrideTarif"
            );
        if (this.$parent.detailPanel && this.$parent.event) {
            // let payment = this.$parent.detailPanel && this.$parent.detailPanel.data && this.$parent.detailPanel.data.price ? this.$parent.detailPanel.data.price : 0
            const doctorId = this.$parent.detailPanel && this.$parent.detailPanel.dokterId
                    ? this.$parent.detailPanel.dokterId
                    : 0;
            this.jadwalStatics.id =  doctorId
            this.jadwalStatics.doctorName =
                this.$parent.detailPanel && this.$parent.detailPanel.namaDokter
                    ? this.$parent.detailPanel.namaDokter
                    : "";
            this.jadwalStatics.jamMulai = this.$parent.detailPanel.jamMulai;
            this.jadwalStatics.jamSelesai = this.$parent.detailPanel.jamSelesai;
            this.teleconsult = this.$parent.detailPanel.data && this.$parent.detailPanel.data.telekonsul ? true : false
            this.isSelectedSlot = true
        }
        if (this.$parent.event) {
            let payment = this.$parent.event.jadwal.dokter.tarif;
            this.isSelectedSlot = true
            if (
                this.$parent.detailPanel.price == 0 ||
                this.$parent.detailPanel.price
            ) {
                this.price = this.$parent.detailPanel.price;
                this.firstPrice = this.$parent.detailPanel.price;
            } else if (payment) {
                this.price = payment.jumlah;
                this.firstPrice = payment.jumlah;
            }
        }
    },
};
</script>

<style lang="scss" scoped></style>
